// Use select2 for all select inputs
$('select').select2();

// Improved file inputs
$(function() {
    // Attach 'fileselect' event to all file inputs on the page.
    $(document).on('change', ':file', function() {
        var input = $(this),
            label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
        input.trigger('fileselect', label);
    });
    // Watch for 'fileselect' event.
    $(document).ready(function() {
        $(':file').on('fileselect', function(event, label) {
            var input = $(this).parents('.input-group').find(':text')
            input.val(label);
        });
    });
});

// Format bootstrap datepicker for modules start/end.
$('.input-daterange').datepicker({
    format: "dd-mm-yyyy",
    minViewMode: 1,
    maxViewMode: 2,
    todayBtn: "linked",
    autoclose: true
});

// Check solution processed status.
function poll() {
    $.ajax({
        url: window.location.href + '/status',
        dataType: 'json',
        type: 'get',
        success: function (data) {
            if (data.processed) {
    			location.reload();
            }
            else {
                setTimeout(poll, 1000);
            }
        },
        error: function () {
            console.log('Error: Unable to check solution status.');
        }
    });
}